// Paths
import {
  TEAM,
  SINGLE_CREATE,
  TRANSACTIONS,
  IN_PROGRESS,
  SINGLE_UPDATE,
  IN_STATUS_CHECK,
  BULK_CREATE,
  BULK_UPDATE_STATUS,
  BULK_UPDATE,
  SD_LANDING_PAGE,
  SD_SCHEDULING,
  SD_SCHEDULED_DATA,
  SD_DETAILS,
  GROUP_PAYDATE,
  IOB,
  IOB_BULK_CREATE,
  TIM_AKSES_PAGE,
  ADD_PIC_USER,
  INVOICES,
  LIMIT_REQUEST,
  LIMIT_REQUEST_APPLICATION,
} from "global/paths";

// Icons (These are usually small and don't need code splitting)
import { BsFillPeopleFill } from "react-icons/bs";
import { TbChartHistogram } from "react-icons/tb";
import { CiMoneyBill } from "react-icons/ci";
import { AiOutlineStock, AiOutlineFileProtect, AiOutlineApartment, AiOutlineIdcard } from "react-icons/ai";
import React from "react";
import LimitRequest from "pages/LimitRequest";
import {
  ROLE_EMPLOYEE,
  ROLE_GROUP_PAYDATE,
  ROLE_SD,
  ROLE_TEAM_ACCESS,
  ROLE_TRANSACTION,
  ROLE_INVOICES,
  ROLE_LIMIT,
} from "./constants";

// Team
const TeamStack = React.lazy(() => import("pages/TeamStack"));
const Team = React.lazy(() => import("pages/Team"));
const SingleCreate = React.lazy(() => import("pages/SingleCreate"));
const SingleUpdate = React.lazy(() => import("pages/SingleUpdate"));
const InProgress = React.lazy(() => import("pages/InProgress"));
const Create = React.lazy(() => import("pages/BulkCreateAndUpdate/create"));
const BulkUpdate = React.lazy(() => import("pages/BulkCreateAndUpdate/BulkUpdate"));
const BulkUpdateStatus = React.lazy(() => import("pages/BulkCreateAndUpdate/BulkUpdateStatus"));
const InStatusCheck = React.lazy(() => import("pages/InStatusCheck"));

// Transactions
const Transactions = React.lazy(() => import("pages/Transactions"));

// Salary Disbursement
const SDStack = React.lazy(() => import("pages/SDStack"));
const SalaryDisbursement = React.lazy(() => import("pages/SalaryDisbursement"));
const SDScheduling = React.lazy(() => import("pages/SDScheduling"));
const SDScheduledData = React.lazy(() => import("pages/SDScheduledData"));
const SDDetails = React.lazy(() => import("pages/SDDetails"));

// Group Pay Date and Other Pages
const GroupPayDate = React.lazy(() => import("pages/GroupPayDate"));
const IOBProcessData = React.lazy(() => import("pages/IOBProcessData"));
const TimAkses = React.lazy(() => import("pages/TimAkses"));
const AddPIC = React.lazy(() => import("pages/AddPIC"));
const TeamOutletPage = React.lazy(() => import("pages/TeamOutletPage"));
const Invoices = React.lazy(() => import("pages/Invoices"));
const LimitRequestsLanding = React.lazy(() => import("pages/LimitRequestsLanding"));

const employeeRoute = {
  id: 1,
  name: "sideBar.manageTeam",
  element: <TeamStack />,
  path: TEAM,
  isMainFlow: true,
  isInitialOnboarding: false,
  icon: <BsFillPeopleFill size="24px" style={{ marginBottom: "-5px" }} />,
  amplitudeEvent: "team_menu_clicked",
  children: [
    {
      id: 1,
      element: <Team />,
      path: TEAM,
    },
    {
      id: 2,
      element: <SingleCreate />,
      path: SINGLE_CREATE,
    },
    {
      id: 3,
      element: <InProgress />,
      path: IN_PROGRESS,
    },
    {
      id: 4,
      element: <SingleUpdate />,
      path: SINGLE_UPDATE,
    },
    {
      id: 5,
      element: <InStatusCheck />,
      path: IN_STATUS_CHECK,
    },
    {
      id: 6,
      element: <Create />,
      path: BULK_CREATE,
    },
    {
      id: 7,
      element: <BulkUpdate />,
      path: BULK_UPDATE,
    },
    {
      id: 8,
      element: <BulkUpdateStatus />,
      path: BULK_UPDATE_STATUS,
    },
  ],
  accessKey: ROLE_EMPLOYEE,
};

export const iobRoute = {
  id: 6,
  name: "sideBar.initialOnboarding",
  isInitialOnboarding: true,
  isMainFlow: false,
  element: <TeamStack />,
  path: IOB,
  icon: <BsFillPeopleFill size="24px" style={{ marginBottom: "-5px" }} />,
  amplitudeEvent: "onboard_employee_page_clicked",
  children: [
    {
      id: 1,
      element: <IOBProcessData />,
      path: IOB,
    },
    {
      id: 2,
      element: <Create />,
      path: IOB_BULK_CREATE,
    },
  ],
  accessKey: null,
};

const routeData = [
  employeeRoute,
  iobRoute,
  {
    id: 2,
    name: "sideBar.groupAndPayDate",
    isInitialOnboarding: true,
    isMainFlow: true,
    element: <GroupPayDate />,
    path: GROUP_PAYDATE,
    icon: <AiOutlineApartment size="24px" style={{ marginBottom: "-5px" }} />,
    amplitudeEvent: "group&paydate_menu_clicked",
    accessKey: ROLE_GROUP_PAYDATE,
  },
  {
    id: 3,
    name: "sideBar.transactionHistory",
    element: <Transactions />,
    path: TRANSACTIONS,
    isMainFlow: true,
    icon: <TbChartHistogram size="24px" style={{ marginBottom: "-5px" }} />,
    amplitudeEvent: "transactions_menu_clicked",
    accessKey: ROLE_TRANSACTION,
  },
  {
    id: 4,
    name: "sideBar.salaryDisbursement",
    element: <SDStack />,
    isSDA: true,
    isMainFlow: true,
    path: SD_LANDING_PAGE,
    icon: <CiMoneyBill size="24px" style={{ marginBottom: "-5px" }} />,
    amplitudeEvent: "salary_disbursement_menu_clicked",
    children: [
      {
        id: 1,
        element: <SalaryDisbursement />,
        path: SD_LANDING_PAGE,
      },
      {
        id: 2,
        element: <SDScheduling />,
        path: SD_SCHEDULING,
      },
      {
        id: 3,
        element: <SDScheduledData />,
        path: SD_SCHEDULED_DATA,
      },
      {
        id: 4,
        element: <SDDetails />,
        path: SD_DETAILS,
      },
    ],
    accessKey: ROLE_SD,
  },
  {
    id: 5,
    name: "sideBar.timAkses",
    element: <TeamOutletPage />,
    isMainFlow: true,
    path: TIM_AKSES_PAGE,
    icon: <AiOutlineIdcard size="24px" style={{ marginBottom: "-5px" }} />,
    amplitudeEvent: "teamManagementPageViewed",
    accessKey: ROLE_TEAM_ACCESS,
    children: [
      {
        id: 1,
        element: <TimAkses />,
        path: TIM_AKSES_PAGE,
      },
      {
        id: 2,
        element: <AddPIC />,
        path: ADD_PIC_USER,
      },
    ],
  },
  {
    id: 7,
    name: "sideBar.invoice",
    isInitialOnboarding: false,
    isMainFlow: true,
    element: <Invoices />,
    path: INVOICES,
    icon: <AiOutlineFileProtect size="24px" style={{ marginBottom: "-5px" }} />,
    amplitudeEvent: "invoice_page_clicked",
    accessKey: ROLE_INVOICES,
  },
  {
    id: 8,
    name: "sideBar.limitRequest",
    isInitialOnboarding: false,
    isMainFlow: true,
    element: <TeamOutletPage />,
    path: LIMIT_REQUEST,
    icon: <AiOutlineStock size="24px" style={{ marginBottom: "-5px" }} />,
    amplitudeEvent: "limit_request_page_clicked",
    accessKey: ROLE_LIMIT,
    children: [
      {
        id: 1,
        element: <LimitRequestsLanding />,
        path: LIMIT_REQUEST,
      },
      {
        id: 1,
        element: <LimitRequest />,
        path: LIMIT_REQUEST_APPLICATION,
      },
    ],
  },
];
/**
 *
 */

export default routeData;

export const routeToAccessKey = {
  [TEAM]: ROLE_EMPLOYEE,
  [SINGLE_CREATE]: ROLE_EMPLOYEE,
  [IN_PROGRESS]: ROLE_EMPLOYEE,
  [SINGLE_UPDATE]: ROLE_EMPLOYEE,
  [IN_STATUS_CHECK]: ROLE_EMPLOYEE,
  [BULK_CREATE]: ROLE_EMPLOYEE,
  [BULK_UPDATE]: ROLE_EMPLOYEE,
  [BULK_UPDATE_STATUS]: ROLE_EMPLOYEE,
  [IOB]: null, // No specific role required for this route
  [IOB_BULK_CREATE]: null, // Specific to Initial Onboarding
  [GROUP_PAYDATE]: ROLE_GROUP_PAYDATE,
  [TRANSACTIONS]: ROLE_TRANSACTION,
  [SD_LANDING_PAGE]: ROLE_SD,
  [SD_SCHEDULING]: ROLE_SD,
  [SD_SCHEDULED_DATA]: ROLE_SD,
  [SD_DETAILS]: ROLE_SD,
  [TIM_AKSES_PAGE]: ROLE_TEAM_ACCESS,
  [ADD_PIC_USER]: ROLE_TEAM_ACCESS,
  [INVOICES]: ROLE_INVOICES,
  [LIMIT_REQUEST]: ROLE_LIMIT,
  [LIMIT_REQUEST_APPLICATION]: ROLE_LIMIT,
};
