import { View } from "native-base";
import React, { useState, useRef } from "react";
import { AiOutlineEye, AiOutlineUpload, AiOutlineDelete } from "react-icons/ai";
import PropTypes from "prop-types";

const FileUploader = ({ onUpload, onDelete, onFileNameChange }) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open the file input dialog programmatically
    }
  };

  const truncateFileName = (fileName) => {
    const name = fileName.substring(0, 10); // Get first 10 characters
    const extension = fileName.substring(fileName.lastIndexOf(".")); // Get file extension
    return fileName.length > 13 ? `${name}...${extension}` : name + extension; // Add '...' if length exceeds 13 (10 + 3 for '...')
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // If the user cancels the file dialog, do nothing
    if (!selectedFile) {
      if (file) {
        setError(""); // Clear any previous errors
      } else {
        setError("No file selected"); // Show error only if no file was previously selected
      }
      return;
    }

    // Validate file type
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedTypes.includes(selectedFile.type)) {
      setError("File type not supported. Please upload a PNG, JPEG, or PDF file.");
      return;
    }

    // Validate file size (20MB max)
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    if (selectedFile.size > maxSize) {
      setError("File size exceeds the 20MB limit.");
      return;
    }

    // Clear errors and update file state
    setError("");
    setFile(selectedFile);

    // Create a URL for file preview
    const previewUrl = URL.createObjectURL(selectedFile);
    setFileUrl(previewUrl);

    // Truncate the file name to 10 characters and preserve the extension
    const truncatedFileName = truncateFileName(selectedFile.name);
    onFileNameChange(truncatedFileName); // Pass truncated file name to the parent
  };

  const handleUpload = () => {
    if (!file) {
      setError("No file selected for upload");
      return;
    }

    // Trigger parent callback
    onUpload();

    // Reset file after upload
    setFile(null);
    setFileUrl("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePreview = () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      setError("No file available for preview.");
    }
  };

  const handleDelete = () => {
    // Clear file
    setFile(null);
    setFileUrl("");
    setError("");

    // Trigger parent callback
    onDelete();

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
    onFileNameChange(""); // Clear the file name in the parent
  };

  return (
    <View>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {/* Icon for triggering file input */}
        <AiOutlineUpload
          size="18px"
          style={{ marginRight: "8px", cursor: "pointer" }}
          onClick={handleIconClick}
          title="Click to upload a file"
        />
        {/* Icon for previewing the selected file */}
        {file && (
          <AiOutlineEye
            size="18px"
            style={{ marginRight: "8px", cursor: "pointer" }}
            onClick={handlePreview}
            title="Click to preview the file"
          />
        )}
        {/* Icon for deleting the selected file */}
        {file && (
          <AiOutlineDelete
            size="18px"
            style={{ marginRight: "8px", cursor: "pointer", color: "red" }}
            onClick={handleDelete}
            title="Click to delete the file"
          />
        )}
        {/* Hidden file input */}
        <input
          type="file"
          accept=".png,.jpeg,.jpg,.pdf"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        {/* Display selected file or errors */}
        {/* {file && <p>Selected file: {file.name}</p>} */}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {/* Upload button */}
      </div>
      {file && (
        <button
          type="button" // Add this attribute
          onClick={handleUpload}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007BFF",
            color: "#FFF",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Upload
        </button>
      )}
    </View>
  );
};

FileUploader.propTypes = {
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  onFileNameChange: PropTypes.func,
};
FileUploader.defaultProps = {
  onUpload: () => {},
  onDelete: () => {},
  onFileNameChange: () => {},
};

export default FileUploader;
