import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCellContent from "atoms/TableCellContent";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";
import { numberWithDots } from "utils/helpers";

function TableLimitDecision() {
  const { t } = useTranslation("common");
  const requestId = t("limitRequest.decisionList.requestId");
  const requestType = t("limitRequest.decisionList.requestType");
  const requestDate = t("limitRequest.decisionList.requestDate");
  const requestEWALimit = t("limitRequest.decisionList.requestEWALimit");
  const requestSDLimit = t("limitRequest.decisionList.requestSDLimit");
  const mandatoryDocumentDecision = t("limitRequest.decisionList.mandatoryDocumentDecision");
  const additionalDocumentDecision = t("limitRequest.decisionList.additionalDocumentDecision");
  const finalDecision = t("limitRequest.decisionList.finalDecision");
  const totalApprovedLimit = t("limitRequest.decisionList.totalApprovedLimit");
  const lastUpdatedAt = t("limitRequest.decisionList.lastUpdatedAt");
  return (
    <TableContainer
      style={{
        marginTop: 24,
      }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead
          style={{
            backgroundColor: colors.neutral.mist,
          }}
        >
          <TableRow>
            <TableCellContent variant="xs-bold" align="center">
              Details
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              Status
            </TableCellContent>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestId}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              test123
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestType}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              EWA & SD
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestDate}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {dayjs(new Date()).toString()}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestEWALimit}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {numberWithDots(10000000000)}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {requestSDLimit}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {numberWithDots(20000000000)}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {mandatoryDocumentDecision}{" "}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              PENDING
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {additionalDocumentDecision}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              PENDING
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {finalDecision}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              PENDING
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {totalApprovedLimit}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {numberWithDots(20000000000 / 2)}
            </TableCellContent>
          </TableRow>
          <TableRow>
            <TableCellContent variant="xs-bold" align="left">
              {lastUpdatedAt}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="left">
              {dayjs(new Date()).toString()}
            </TableCellContent>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableLimitDecision;
