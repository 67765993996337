import React from "react";
import { View, Button } from "native-base";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const DecisionTabActions = ({ requestType }) => {
  const { values, errors } = useFormikContext();

  // Check mandatory documents
  const allMandatoryUploaded =
    Array.isArray(values.mandatoryDocuments) && values.mandatoryDocuments.every((doc) => doc.status === "UPLOADED");

  // Check additional documents
  const allAdditionalUploaded =
    Array.isArray(values.additionalDocuments) && values.additionalDocuments.every((doc) => doc.status === "UPLOADED");

  // Check limits validity
  let limitsValid = true;
  if (requestType === "0" || requestType === "2") {
    // EWA needed
    limitsValid = limitsValid && values.ewaLimit && !errors.ewaLimit;
  }
  if (requestType === "1" || requestType === "2") {
    // SD needed
    limitsValid = limitsValid && values.sdLimit && !errors.sdLimit;
  }

  let canSubmit = false;
  if (requestType === "0") {
    // EWA only: mandatory docs + EWA limit
    canSubmit = allMandatoryUploaded && limitsValid;
  } else if (requestType === "1") {
    // SD only: mandatory docs + additional docs + SD limit
    canSubmit = allMandatoryUploaded && allAdditionalUploaded && limitsValid;
  } else if (requestType === "2") {
    // Both: mandatory docs + additional docs + EWA & SD limits
    canSubmit = allMandatoryUploaded && allAdditionalUploaded && limitsValid;
  }

  return (
    <View marginTop="16px">
      <Button type="submit" disabled={!canSubmit}>
        Submit
      </Button>
    </View>
  );
};

DecisionTabActions.propTypes = {
  requestType: PropTypes.string.isRequired,
};

export default DecisionTabActions;
