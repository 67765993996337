import * as Yup from "yup";

const sValidationNumberOnly = "basicValidation.numbersOnly";
const sValidationTooShort = "basicValidation.tooShort";
export const sValidationMissingField = "basicValidation.missingFields";
const sValidationTooLong = "basicValidation.tooLong";

const hasInvalidChars = (value) => /[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(value);
const startsWithDot = (value) => value.charAt(0) === ".";
const countryRules = {
  "+60": { min: 9, max: 10 },
  "+65": { min: 7, max: 10 },
  "+91": { min: 10, max: 10 },
  default: { min: 10, max: 16, specificStart: "628" },
};

export function getSalaryValidation(t) {
  return Yup.string()
    .required(t(sValidationMissingField))
    .matches(/^\d+$/, t(sValidationNumberOnly))
    .test({
      name: "maxLength",
      test: (val) => val && val.length < 13,
      message: t(sValidationTooLong),
    })
    .test({
      name: "noLeadingDot",
      test: (val) => !val?.startsWith("."),
      message: t(sValidationNumberOnly),
    });
}

export const phoneValidationSchema = Yup.string()
  .required(sValidationMissingField)
  .test("invalid-characters", sValidationNumberOnly, (val) => {
    return !hasInvalidChars(val) && !startsWithDot(val);
  })
  .test("length-check", function validateLength(val) {
    const { countryCode } = this.parent; // Assuming countryCode is part of the form values
    const rules = countryRules[countryCode] || countryRules["default"];

    if (val.length > rules.max) return sValidationTooLong;
    if (val.length < rules.min) return sValidationTooShort;

    return true;
  })
  .test("specific-start-check", "basicValidation.properFormat", function validateStart(val) {
    const { countryCode } = this.parent;
    const rules = countryRules[countryCode] || countryRules["default"];
    return !rules.specificStart || val.startsWith(rules.specificStart);
  });

export const validationCheck = {
  otp: (val) => {
    if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
      return sValidationNumberOnly;
    }
    if (val > 0 && val.length < 4) {
      return sValidationTooShort;
    }
    return "";
  },
  phone: (val, countryCode) => {
    if (val === "") return sValidationMissingField;

    const rules = countryRules[countryCode] || countryRules["default"];

    if (hasInvalidChars(val) || startsWithDot(val)) return sValidationNumberOnly;
    if (val.length > rules.max) return sValidationTooLong;
    if (val.length < rules.min) return sValidationTooShort;
    if (rules.specificStart && !val.startsWith(rules.specificStart)) return "basicValidation.properFormat";

    return "";
  },
  birthDate: (val) => {
    let currentDate = new Date();
    if (new Date(val)?.getTime() > currentDate?.getTime()) {
      return "basicValidation.futureDate";
    }

    return "";
  },
  dateJoined: (val) => {
    let currentDate = new Date();
    if (new Date(val)?.getTime() > currentDate?.getTime()) {
      return "basicValidation.futureDate";
    }

    return "";
  },
};

export const validate = (t) =>
  Yup.object().shape(
    {
      name: Yup.string()
        .required(t(sValidationMissingField))
        .transform((originalValue) => {
          return originalValue ? originalValue.trim() : originalValue;
        }),
      gender: Yup.string().required(t(sValidationMissingField)),
      employeeId: Yup.string().required(t(sValidationMissingField)).matches(/^\S*$/, t("basicValidation.noSpaces")),
      status: Yup.string().required(t(sValidationMissingField)),
      suspensionCategory: Yup.string().when("status", {
        is: (status) => status === "inactive" || status === "withdraw_suspended",
        then: (schema) => schema.required(t(sValidationMissingField)),
      }),
      suspendedReason: Yup.string().when(["status", "suspensionCategory"], {
        is: (status, suspensionCategory) =>
          (status === "inactive" || status === "withdraw_suspended") && suspensionCategory?.includes("Other"),
        then: (schema) => schema.required(t(sValidationMissingField)),
      }),
      payDate: Yup.string().when(["group", "groupPayDates"], {
        is: (group, groupPayDates) => {
          return !group && (groupPayDates || [])?.length !== 0;
        },
        then: (schema) => schema.required(t("basicValidation.pleaseSelectGroupFirst")),
        otherwise: (schema) => schema.required(t(sValidationMissingField)),
      }),
      salary: getSalaryValidation(t),
      ktp: Yup.string()
        .required(t(sValidationMissingField))
        .matches(/^[0-9]*$/, t(sValidationNumberOnly))
        .test({
          name: "maxLength",
          test: (val) => val && val.length <= 16,
          message: t(sValidationTooLong),
        })
        .test({
          name: "minLength",
          test: (val) => val && val.length >= 16,
          message: t(sValidationTooShort),
        })
        .test({
          name: "noLeadingDot",
          test: (val) => !val?.startsWith("."),
          message: t(sValidationNumberOnly),
        })
        .transform((originalValue) => {
          return originalValue.trim();
        }),
      bankNumber: Yup.string()
        .required(t(sValidationMissingField))
        .test({
          name: "numbersOnly",
          test: (val) => {
            const trimmedValue = val.trim();
            return !/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(trimmedValue);
          },
          message: sValidationNumberOnly,
        })
        .transform((originalValue) => {
          return originalValue.trim();
        }),
      beneficiaryName: Yup.string().required(t(sValidationMissingField)),
      // .matches(/^[^!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,0-9\`\[\]\{\}]*$/, t("basicValidation.lettersOnly")),
      group: Yup.string().when(["payDate", "groupPayDates"], {
        is: (payDate, groupPayDates) => {
          return !payDate && (groupPayDates || [])?.length !== 0;
        },
        then: (schema) => schema.required(t(sValidationMissingField)),
      }),
    },
    ["group", "payDate"],
  );
