import Amplitude from "amplitude-js";
// import secureStore from "utils/secureStore";
// import jwtDecode from "jwt-decode";
// import config from "../../config";

const amplitude = Amplitude.getInstance();

const AmplitudeHelper = {
  init: () => {
    // amplitude.init(config.AMPLITUDE_KEY);
  },
  logEvent: async () => {
    // const token = await secureStore.getItemAsync("accessToken");
    // if (!token) {
    //   // Token doesn't exist or is falsy
    // } else {
    //   const decodedToken = jwtDecode(token);
    //   amplitude.setUserId(decodedToken?.employerId?.toString() || "");
    // }
    // amplitude.logEvent(eventName, data);
  },
  setUserId: () => {
    // const sendingUserId = userID;
    // amplitude.setUserId(sendingUserId.toString());
    // amplitude.setUserProperties({
    //   environment: config.ENV_SENTRY,
    //   employerId: employerID,
    // });
  },
  setLocation: async () => {
    // amplitude.setUserProperties({
    //   Latitude: lat,
    //   Longitude: lng,
    // });
  },
  setUserProperties: () => {
    //  amplitude.setUserProperties(data);
  },
  clearUserProperties: () => {
    amplitude.clearUserProperties();
  },
};

export default AmplitudeHelper;
