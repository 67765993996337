import PropTypes from "prop-types";
import { VStack, Text, Box } from "native-base";
import NoticeBanner from "atoms/NoticeBanner";
import { memo, useMemo } from "react";
import styles from "./styles";

export function shouldrenderNoticeBanner(showNoticeBanner) {
  return showNoticeBanner && <NoticeBanner noticeText="banner.wsBannerText" />;
}
const ContentContainer = memo(({ showNoticeBanner, title, children, innerContainerProps, parentStyle, ...props }) => {
  const noticeBanner = useMemo(() => {
    return showNoticeBanner ? <NoticeBanner noticeText="banner.wsBannerText" /> : null;
  }, [showNoticeBanner]);

  return (
    <VStack style={[styles.content, parentStyle]} {...props}>
      {noticeBanner}
      <Box flex={1} {...innerContainerProps}>
        <Text testID="txtTitle" variant="md-bold" style={styles.title}>
          {title}
        </Text>
        {children}
      </Box>
    </VStack>
  );
});

ContentContainer.propTypes = {
  showNoticeBanner: PropTypes.bool,
  title: PropTypes.string,
  innerContainerProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

ContentContainer.defaultProps = {
  showNoticeBanner: false,
  title: "",
  innerContainerProps: {},
};

export default ContentContainer;
