// Team
export const TEAM = "/";
export const SINGLE_CREATE = "/single";
export const SINGLE_UPDATE = "/single_update";
export const IN_PROGRESS = "/progress";
export const IN_STATUS_CHECK = "/check_status";
export const BULK_CREATE = "/bulk_create";

export const BULK_UPDATE = "/bulk_update";
export const BULK_UPDATE_STATUS = "/bulk_update_status";

export const IOB = "/iob";
export const IOB_PROCESS_DATA = "/iob/process-page";
export const IOB_BULK_CREATE = "/iob/bulk_create";
// Transactions
export const TRANSACTIONS = "/transactions";

// Salary Disbursements
export const SD_LANDING_PAGE = "/sd";
export const SD_SCHEDULING = "/sd/sd_scheduling";
export const SD_SCHEDULED_DATA = "/sd/sd_scheduled_data";
export const SD_DETAILS = "/sd/sd_details";

// Group and payment

export const GROUP_PAYDATE = "/groupAndPayDate";
export const TIM_AKSES_PAGE = "/timakses";
export const ADD_PIC_USER = "/timakses/addpic";
export const INVOICES = "/invoices";
export const LIMIT_REQUEST = "/limit_request";
export const LIMIT_REQUEST_APPLICATION = "/limit_request/application";
