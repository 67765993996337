import { TableRow, TableBody, TableHead, Table, Paper, TableContainer } from "@mui/material";
import TableCellContent from "atoms/TableCellContent";
import colors from "theme/colors";
import { View } from "native-base";
import FileUploader from "atoms/FileUploader";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function TableMandatoryDocuments({ name }) {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("common");

  // Get the documents array from formik values based on the passed name prop
  const docs = values[name] || [];

  const handleFileNameChange = (documentKey, fileName) => {
    const updatedDocs = docs.map((doc) => (doc.key === documentKey ? { ...doc, fileName } : doc));
    setFieldValue(name, updatedDocs);
  };

  const handleFileUpload = (key, action) => {
    const updatedDocs = docs.map((doc) =>
      doc.key === key ? { ...doc, status: action === "upload" ? "UPLOADED" : "PENDING" } : doc,
    );
    setFieldValue(name, updatedDocs);
  };

  return (
    <TableContainer
      style={{
        marginTop: 24,
      }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead
          style={{
            backgroundColor: colors.neutral.mist,
          }}
        >
          <TableRow>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.docType")}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.fileName")}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.file")}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.status")}
            </TableCellContent>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((item) => (
            <TableRow key={item.key}>
              <TableCellContent variant="xs-normal" align="left">
                {item.name}
              </TableCellContent>
              <TableCellContent variant="xs-normal" align="center">
                {item.fileName || "No file"}
              </TableCellContent>
              <TableCellContent variant="xs-normal" align="right">
                <View flexDirection="row">
                  <FileUploader
                    onFileNameChange={(fileName) => handleFileNameChange(item.key, fileName)}
                    onUpload={() => handleFileUpload(item.key, "upload")}
                    onDelete={() => handleFileUpload(item.key, "delete")}
                  />
                </View>
              </TableCellContent>
              <TableCellContent variant="xs-normal" align="center">
                {item.status}
              </TableCellContent>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableMandatoryDocuments.propTypes = {
  name: PropTypes.string.isRequired, // "mandatoryDocuments" or "additionalDocuments"
};

export default TableMandatoryDocuments;
