import { useFormikContext } from "formik";
import { Button, View } from "native-base";

const AdditionalTabActions = ({ setValue, requestType }) => {
  const { values } = useFormikContext();

  // Check if additional docs are all uploaded
  const allAdditionalUploaded =
    Array.isArray(values.additionalDocuments) && values.additionalDocuments.every((doc) => doc.status === "UPLOADED");
  const canProceed = requestType === "0" ? true : allAdditionalUploaded;

  return (
    <View marginTop="16px">
      <Button onPress={() => setValue(2)} disabled={!canProceed}>
        Next
      </Button>
    </View>
  );
};

export default AdditionalTabActions;
