import React, { useState } from "react";
import InputCurrency from "atoms/InputCurrency";
import { Text, View } from "native-base";
import { numberWithDots } from "utils/helpers";
import PropTypes from "prop-types";
import { useField } from "formik";
import colors from "theme/colors";

function LimitInputForm({ testID, sText, name, currentValue }) {
  const [field, meta, helpers] = useField(name);
  const isError = meta.touched && meta.error;
  const [totalValue, setTotalValue] = useState("");
  const onLimitChange = (newValueOrEvent) => {
    // If InputCurrency gives you an event, extract its value only.
    const newValue =
      typeof newValueOrEvent === "object" && newValueOrEvent?.target ? newValueOrEvent.target.value : newValueOrEvent;

    // Store only a primitive (string or number) in Formik state
    helpers.setValue(newValue || "");

    setTotalValue(parseInt(currentValue, 10) + parseInt(newValue, 10));
  };

  const onLimitBlur = () => {
    // On blur, parse and format.
    const numericValue = parseInt(String(field.value).replace(/\./g, ""), 10);
    if (!isNaN(numericValue)) {
      // Convert to a dotted/formatted string if you want.
      helpers.setValue(numberWithDots(String(numericValue)));
    } else {
      // If invalid, revert to empty or null
      helpers.setValue("");
    }
    helpers.setTouched(true);
  };

  return (
    <View flexDirection="row">
      <Text marginLeft="15px" flex={1}>
        {sText}
      </Text>
      <View>
        <InputCurrency
          input={field.value || ""}
          // This should call onLimitChange with a raw string
          onValueChange={onLimitChange}
          onValueBlur={onLimitBlur}
          testID={testID}
          disabled={false}
          customStyles={{ borderColor: isError ? colors.error.rose : colors.neutral.charcoal }}
          withDots={false}
        />
        {isError && <Text style={{ color: "red", marginLeft: 8 }}>{meta.error}</Text>}
        <Text>
          Total {sText} Limit: {numberWithDots(totalValue)}
        </Text>
      </View>
    </View>
  );
}

LimitInputForm.propTypes = {
  testID: PropTypes.string.isRequired,
  sText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default LimitInputForm;
