import { useFormikContext } from "formik";
import { Button, Text, View } from "native-base";
import colors from "theme/colors";

const MandatoryTabActions = ({ requestType, setValue }) => {
  const { values, errors } = useFormikContext();

  // Check if mandatory docs are all uploaded
  const allMandatoryUploaded =
    Array.isArray(values.mandatoryDocuments) && values.mandatoryDocuments.every((doc) => doc.status === "UPLOADED");

  // Check limit validity
  let limitsValid = true;
  if (requestType === "0" || requestType === "2") {
    // EWA required
    limitsValid = limitsValid && values.ewaLimit !== null && values.ewaLimit !== "" && !errors.ewaLimit;
  }
  if (requestType === "1" || requestType === "2") {
    // SD required
    limitsValid = limitsValid && values.sdLimit !== null && values.sdLimit !== "" && !errors.sdLimit;
  }

  const canProceed = allMandatoryUploaded && limitsValid;

  const handleNext = () => {
    setValue(1);
  };

  return (
    <View marginTop="16px">
      <Button
        onPress={handleNext}
        disabled={!canProceed}
        style={{ backgroundColor: canProceed ? colors.secondary.orchidBlue : colors.neutral.darkMist }}
      >
        <Text variant="sm-bold" color={colors.neutral.cotton}>
          Next
        </Text>
      </Button>
    </View>
  );
};

export default MandatoryTabActions;
