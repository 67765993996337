import React from "react";
import PropTypes from "prop-types";
import { HStack, Box, Image, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { WarningAmber } from "assets";
import { EMPLOYER_STATUS_TYPE } from "global/constants";
import { getStore } from "store/storeUtils";
import styles from "./styles";

const NoticeBanner = ({ noticeText }) => {
  const { t } = useTranslation("common");
  const { meData } = getStore();

  if (meData?.status === EMPLOYER_STATUS_TYPE.WITHDRAW_SUSPENDED) {
    return (
      <Box style={styles.banner}>
        <HStack style={{ display: "flex", flex: 1 }} alignItems="center">
          <div style={styles.iconContainer}>
            <Image src={WarningAmber} alt="Warning" style={styles.icon} />
          </div>
          <Text variant="xxs-bold">{t(noticeText)}</Text>
        </HStack>
      </Box>
    );
  }

  return null;
};

NoticeBanner.propTypes = {
  noticeText: PropTypes.string.isRequired,
};

export default NoticeBanner;
