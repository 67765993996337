import TableCell from "@mui/material/TableCell";
import colors from "theme/colors";
import { Text } from "native-base";
import PropTypes from "prop-types";
import React, { memo } from "react";

const TableCellContent = memo(({ align, children, variant }) => (
  <TableCell align={align}>
    <Text variant={variant} color={colors.neutral.charcoal}>
      {children}
    </Text>
  </TableCell>
));

TableCellContent.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

TableCellContent.defaultProps = {
  align: "center",
  variant: "sm-bold",
};

export default TableCellContent;
