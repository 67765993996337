import colors from "theme/colors";

const styles = {
  banner: {
    backgroundColor: colors.primary.softCarnation,
    flexDirection: "row",
    display: "flex",
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: -20,
    marginLeft: -20,
    marginRight: -20,
    paddingRight: 16,
    marginBottom: 10,
    paddingLeft: 10,
  },
  iconContainer: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    width: 24,
  },
  icon: {
    width: 24,
    height: 24,
  },
};

export default styles;
