import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Flex, HStack, Text, View, VStack } from "native-base";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomTabPanel from "atoms/CustomTabPanel";
import { documents } from "global/constants";
import TableMandatoryDocuments from "molecules/TableMandatoryDocuments";
import TableLimitDecision from "molecules/TableLimitDecision";
import LimitInputForm from "atoms/LimitInputForm";
import MandatoryTabActions from "molecules/MandatoryTabActions";
import AdditionalTabActions from "molecules/AdditionalTabActions";
import DecisionTabActions from "molecules/DecisionTabActions";
import ContentContainer from "atoms/ContentContainer";
import colors from "theme/colors";
import { numberWithDots } from "utils/helpers";

function EWALimitCurrentAndRequired(props) {
  const { limit } = props;
  return (
    <View>
      <View flexDirection="row">
        <Text flex={1}>Current EWA Limit </Text>
        <Text>${numberWithDots(limit)}</Text>
      </View>
      <LimitInputForm testID="ewaLimit" sText="EWA" name="ewaLimit" currentValue={limit} />
    </View>
  );
}

function SDLimitCurrentAndRequired(props) {
  const { limit } = props;
  return (
    <View>
      <View flexDirection="row">
        <Text flex={1}>Current SD Limit </Text>
        <Text>${numberWithDots(limit)}</Text>
      </View>
      <LimitInputForm testID="sdLimit" sText="SD" name="sdLimit" currentValue={limit} />
    </View>
  );
}

function shouldShowLimitInputs(requestType, limit) {
  if (requestType === "0") {
    return <EWALimitCurrentAndRequired limit={limit.ewa} />;
  }
  if (requestType === "1") {
    return <SDLimitCurrentAndRequired limit={limit.sd} />;
  }
  if (requestType === "2") {
    return (
      <>
        <View marginBottom="6px">
          <EWALimitCurrentAndRequired />
        </View>
        <View>
          <SDLimitCurrentAndRequired />
        </View>
      </>
    );
  }
  return null;
}

function showDocumentForType(type, t) {
  return documents(t)[type];
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// 1 trillion = 1_000_000_000_000 (12 zeros)
const MAX_LIMIT = 1000000000000;

const numericStringSchema = Yup.string()
  // Remove thousand separators (dots) before validation
  .transform((value) => (value ? value.replace(/\./g, "") : value))
  .test("is-numeric", "Must be a number", (val) => {
    if (!val) return false;
    return /^\d+$/.test(val); // only digits
  })
  .test("no-leading-zeros", "No leading zeros allowed", (val) => {
    if (!val) return false;
    // If length > 1 and starts with '0', it's invalid
    return !(val.length > 1 && val[0] === "0");
  })
  .test("min", "Minimum is 1", (val) => parseInt(val, 10) >= 1)
  .test("max", "Maximum is 1 trillion", (val) => parseInt(val, 10) <= MAX_LIMIT);

const getValidationSchema = (requestType) =>
  Yup.object().shape({
    ewaLimit: Yup.string()
      .nullable()
      .when([], {
        is: () => requestType === "0" || requestType === "2",
        then: (schema) => schema.required("EWA limit is required").concat(numericStringSchema),
        otherwise: (schema) => schema.nullable(),
      }),
    sdLimit: Yup.string()
      .nullable()
      .when([], {
        is: () => requestType === "1" || requestType === "2",
        then: (schema) => schema.required("SD limit is required").concat(numericStringSchema),
        otherwise: (schema) => schema.nullable(),
      }),
    mandatoryDocuments: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          key: Yup.string().required(),
          fileName: Yup.string(),
          status: Yup.string().oneOf(["PENDING", "UPLOADED"]).required(),
        }),
      )
      .test("all-uploaded-mandatory", "All mandatory documents must be uploaded", (value) => {
        if (!value) return true; // If no documents, no check
        // For requestType = 0, 1, or 2, mandatory docs must be uploaded
        if (requestType === "0" || requestType === "1" || requestType === "2") {
          return value.every((doc) => doc.status === "UPLOADED");
        }
        return true;
      }),
    additionalDocuments: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          key: Yup.string().required(),
          fileName: Yup.string(),
          status: Yup.string().oneOf(["PENDING", "UPLOADED"]).required(),
        }),
      )
      .test("all-uploaded-additional", "All additional documents must be uploaded", (value) => {
        // For SD (1) and BOTH (2), additional docs must be uploaded
        if (requestType === "1" || requestType === "2") {
          // If no additional docs are listed, it's implicitly satisfied (no additional docs needed)
          if (!value || value.length === 0) return true;
          return value.every((doc) => doc.status === "UPLOADED");
        }
        return true;
      }),
  });

const LimitRequest = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  const location = useLocation();
  const { state } = location;
  const { limits } = state;

  const { t } = useTranslation("common");
  const [searchParams] = useSearchParams();
  const requestType = searchParams.get("requestType"); // "0", "1", or "2"

  const validationSchema = getValidationSchema(requestType);
  const documentsForType = showDocumentForType(requestType, t);

  const initialValues = {
    ewaLimit: null,
    sdLimit: null,
    mandatoryDocuments: documentsForType?.mandatoryDocuments
      ? documentsForType.mandatoryDocuments.map((doc) => ({
          ...doc,
          fileName: "",
          status: "PENDING",
        }))
      : [],
    additionalDocuments: documentsForType?.additionalDocuments
      ? documentsForType.additionalDocuments.map((doc) => ({
          ...doc,
          fileName: "",
          status: "PENDING",
        }))
      : [],
  };

  return (
    <ContentContainer title={t("limitRequests.newApplication")} flex={[1, 1, 1, 0.85, 0.85]}>
      <Flex
        bg={colors.neutral.cotton}
        my="25px"
        mb="20px"
        p="15px"
        py="10px"
        flex={1}
        borderRadius={12}
        maxWidth="1600px"
      >
        <HStack space={3}>
          <VStack style={{ flexWrap: "wrap", flex: 1 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur
              validateOnChange
              onSubmit={() => {
                // Handle the final submission logic here
              }}
            >
              {() => (
                <Form>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <View marginLeft="5px" marginTop="15px" alignSelf="center">
                        {shouldShowLimitInputs(requestType, limits)}
                      </View>
                      <Tabs value={value} onChange={handleChange} aria-label="Limit tab">
                        <Tab label={t("limitRequest.mandatoryDocuments")} {...a11yProps(0)} />
                        <Tab label={t("limitRequest.additionalDocuments")} {...a11yProps(1)} />
                        <Tab label={t("limitRequest.decision")} {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <TableMandatoryDocuments name="mandatoryDocuments" />
                      <MandatoryTabActions requestType={requestType} setValue={setValue} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <TableMandatoryDocuments name="additionalDocuments" />
                      <AdditionalTabActions setValue={setValue} requestType={requestType} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                      <Text>{t("limitRequest.decisionHeader")}</Text>
                      <TableLimitDecision />
                      <DecisionTabActions requestType={requestType} />
                    </CustomTabPanel>
                  </Box>
                </Form>
              )}
            </Formik>
          </VStack>
        </HStack>
      </Flex>
    </ContentContainer>
  );
};

export default LimitRequest;
